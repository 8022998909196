import React from "react";
import Head from "../../components/head.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Layout from "../../components/layout";

const Marcus = () => {
  return (
    <Layout>
      <Head title="Marcus Hammerschmitt" />
      <h3>Marcus Hammerschmitt</h3>
      <p>
        <OutboundLink href="https://ello.co/marcushammerschmitt">
          Ello
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.patreon.com/hammerschmitt">
          Patreon
        </OutboundLink>
      </p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15176161/ello-optimized-e3647c6a.jpg"
        alt="Marcus Hammerschmitt art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/5845859/ello-optimized-593f4240.jpg"
        alt="Marcus Hammerschmitt art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15224459/ello-optimized-18a068c9.jpg"
        alt="Marcus Hammerschmitt art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/8625429/ello-optimized-14e23d1d.jpg"
        alt="Marcus Hammerschmitt art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15363104/ello-optimized-763f3876.jpg"
        alt="Marcus Hammerschmitt art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15370268/ello-optimized-b299166b.jpg"
        alt="Marcus Hammerschmitt art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/2654917/ello-optimized-d9ef5e73.jpg"
        alt="Marcus Hammerschmitt art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/5405817/ello-optimized-42a2f99e.jpg"
        alt="Marcus Hammerschmitt art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/12401167/ello-optimized-aa22b34d.jpg"
        alt="Marcus Hammerschmitt art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15357178/ello-optimized-55e845a6.jpg"
        alt="Marcus Hammerschmitt art"
      />
    </Layout>
  );
};

export default Marcus;
